/*
 CryptoJS v3.1.2
 code.google.com/p/crypto-js
 (c) 2009-2013 by Jeff Mott. All rights reserved.
 code.google.com/p/crypto-js/wiki/License
 */
import CryptoJS from './hmac-sha256'
(function () {
  var h = CryptoJS, j = h.lib.WordArray
  h.enc.Base64 = {
    stringify: function (b) {
      var e = b.words, f = b.sigBytes, c = this._map
      b.clamp()
      b = []
      for (var a = 0; a < f; a += 3)for (var d = (e[a >>> 2] >>> 24 - 8 * (a % 4) & 255) << 16 | (e[a + 1 >>> 2] >>> 24 - 8 * ((a + 1) % 4) & 255) << 8 | e[a + 2 >>> 2] >>> 24 - 8 * ((a + 2) % 4) & 255,
                                           g = 0; 4 > g && a + 0.75 * g < f; g++)b.push(c.charAt(d >>> 6 * (3 - g) & 63))
      if (e = c.charAt(64))for (; b.length % 4;)b.push(e);
      return b.join('')
    }, parse: function (b) {
      var e = b.length, f = this._map, c = f.charAt(64)
      c && (c = b.indexOf(c), -1 != c && (e = c))
      for (var c = [], a = 0, d = 0; d <
      e; d++)if (d % 4) {
        var g = f.indexOf(b.charAt(d - 1)) << 2 * (d % 4), h = f.indexOf(b.charAt(d)) >>> 6 - 2 * (d % 4)
        c[a >>> 2] |= (g | h) << 24 - 8 * (a % 4)
        a++
      }
      return j.create(c, a)
    }, _map: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  }
})()
